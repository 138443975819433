
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import resultApi from "@/core/services/Result";
import { useI18n } from "vue-i18n";
import { UploadFilled } from "@element-plus/icons-vue";

export default defineComponent({
    name: "administration-results",
    data: function () {
        return {
            results: [],
            uploadUrl: "",
            headers: [] as any,
        };
    },
    methods: {
        getResults() {
            resultApi.getResults().then((response) => {
                this.results = response.data["results"];
            });
        },
        uploadResult(item) {
            console.log(item);
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs("Résultats périmètre", []);
        const submitButton = ref<HTMLElement | null>(null);

        return { t, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs("Résultats périmètre", []);
        },
    },
    created() {
        this.uploadUrl = resultApi.uploadResultUrl;
        this.headers = resultApi.headers;
        this.getResults();
    },
});
