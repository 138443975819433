import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";

const GET_RESULTS_URL = "result/get-results";
const GET_REPORT_URL = "result/get-report/:id";
export const GET_ALL_REPORTS_URL = "result/dl-all-reports/";
const UPLOAD_RESULT = "result/upload/";

export default {
    getResults() {
        ApiService.setHeader();
        return ApiService.get(GET_RESULTS_URL).then((response) => {
            return response.data;
        });
    },
    getReport(pk) {
        ApiService.setHeader();
        return ApiService.get(GET_REPORT_URL.replace(':id', pk)).then((response) => {
            return response.data;
        });
    },
    getAllReports(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_ALL_REPORTS_URL, payload).then((response) => {
            return response;
        });
    },
    

    uploadResultUrl: ApiService.returnUrl() + UPLOAD_RESULT,
    headers: ApiService.returnHeaders(),
};
