<template>
    <div class="mb-5 mb-xl-8 card">
        <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1">Résultats périmètre</span>
            </h3>
            <div class="align-items-end flex-column">
                <a class="btn btn-primary btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_1"> Uploader les résulats </a>
            </div>
        </div>
        <div class="card-body py-3">
            <div class="table-responsive">
                <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                    <thead>
                        <tr class="fw-bolder text-muted">
                            <th class="min-w-150px">Nom du fichier</th>
                            <th class="min-w-150px">Fichier</th>
                            <th class="min-w-150px">Campagne</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="result in results" v-bind:key="result.pk">
                            <td>
                                <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{ result.name }}</a>
                            </td>
                            <td>
                                <span class="badge-light badge text-primary">{{ result.tag.label }}</span>
                            </td>
                            <td v-if="result.campaign">
                                <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{ result.campaign.label }}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Uploader les résulats</h5>

                    <!--begin::Close-->
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                        <span class="svg-icon svg-icon-2x"></span>
                    </div>
                    <!--end::Close-->
                </div>

                <div class="modal-body">
                    <el-upload class="upload-demo w-100" drag :action="this.uploadUrl" :headers="this.headers" multiple>
                        <el-icon class="el-icon--upload">
                            <upload-filled />
                        </el-icon>
                        <div class="el-upload__text">Déposer vos fichiers ici</div>
                    </el-upload>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import resultApi from "@/core/services/Result";
import { useI18n } from "vue-i18n";
import { UploadFilled } from "@element-plus/icons-vue";

export default defineComponent({
    name: "administration-results",
    data: function () {
        return {
            results: [],
            uploadUrl: "",
            headers: [] as any,
        };
    },
    methods: {
        getResults() {
            resultApi.getResults().then((response) => {
                this.results = response.data["results"];
            });
        },
        uploadResult(item) {
            console.log(item);
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs("Résultats périmètre", []);
        const submitButton = ref<HTMLElement | null>(null);

        return { t, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs("Résultats périmètre", []);
        },
    },
    created() {
        this.uploadUrl = resultApi.uploadResultUrl;
        this.headers = resultApi.headers;
        this.getResults();
    },
});
</script>
